enum WebsiteName {
  MALIKA = 'MALIKA',
  MLBB = 'MLBB',
  NAKO = 'NAKO',
  WEDUS = 'WEDUS',
  MCL = 'MCL',
}

export default WebsiteName;

