import { CloudArrowDownIcon } from '@heroicons/react/20/solid';
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Range } from 'react-date-range';
// import { DebounceInput } from 'react-debounce-input';
import { Helmet } from 'react-helmet';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { LeafIcon } from '../../../../Assets';
import LoadingIndicator from '../../../../Components/LoadingIndicator';
import Select from '../../../../Components/Select';
import {
  classNames,
  errorMessage,
  pageArray,
  thousandSeparator,
} from '../../../../Helper';
import { WebsiteName } from '../../../../constant';
import { FetchModel, FetchType, UserModel } from '../../../../types';
import DateRangePickerPlayer from './DateRangePickerPlayer';
import LineChartPlayer from './LineChartPlayer';
import TagsInput from '../../../../Components/InputTag';

const NoDataPlayer = () => (
  <div className='opacity-50 w-full text-center py-20 h-auto'>
    <div className='w-24 m-auto'>
      <img src={LeafIcon.default} alt='No statements...' className='w-full' />
    </div>

    <div>Tidak ada data player...</div>
  </div>
);

const page_size = 100;
const AREA = { NEW_PLAYER: 'NEW_PLAYER' };

const Pemain = () => {
  const [tableData, setTableData] = useState<UserModel[]>([]);
  const [selectedReferral, setSelectedReferral] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [fetchAllowed, set_fetch_allowed] = useState(false);

  const [selectedWebsite, setSelectedWebsite] = useState<WebsiteName | null>(
    null
  );

  const [selectionRange, setSelectionRange] = useState<Range>({
    startDate: DateTime.now().minus({ day: 7 }).toJSDate(),
    endDate: DateTime.now().minus({ day: 1 }).toJSDate(),
    key: 'selection',
  });

  const [start_date, set_start_date] = useState<DateTime>(
    DateTime.now().minus({ day: 7 })
  );

  const [end_date, set_end_date] = useState<DateTime>(
    DateTime.now().minus({ day: 1 })
  );

  const [display_date_range_picker, set_display_date_range_picker] =
    useState<boolean>(false);

  const fetchUser = async () => {
    try {
      if (!fetchAllowed) return alert('Data Player hari ini sudah ditarik!');
      if (fetching) return;
      setFetching(true);
      const uri = '/api/player/fetch';
      const method = 'GET';
      const headers = { 'Content-Type': 'application/json' };
      const response = await fetch(uri, { method, headers });
      const res = await response.json();
      if (!response.ok)
        throw new Error(errorMessage(res.message) || res.message);
      alert(res.message);
      set_fetch_allowed(false);
    } catch (error: any) {
      alert(
        error.message || `Terjadi kesalahan ketika fetch data transaksi...`
      );
    } finally {
      setFetching(false);
      getLastFetch();
    }
  };

  const getLastFetch = useCallback(async () => {
    try {
      const uri = '/api/fetch/get-last';
      const method = 'POST';
      const headers = { 'Content-Type': 'application/json' };
      const type = 'PLAYER' as FetchType;
      const body = JSON.stringify({ type });
      const response = await fetch(uri, { method, headers, body });

      const res: {
        message: string;
        data: { lastFetch: { [k in WebsiteName]: FetchModel } };
      } = await response.json();

      if (!response.ok) throw new Error(errorMessage(res) || res.message);

      if (
        Object.values(res.data.lastFetch)?.some(fetch => !fetch?.success) ||
        Object.values(res.data.lastFetch).length !==
          Object.values(WebsiteName).length
      ) {
        set_fetch_allowed(true);
      }
    } catch (error: any) {
      alert(
        error.message || `Terjadi kesalahan ketika mengecek tarikan terakhir...`
      );
    }
  }, []);

  const { promiseInProgress: getting_new_players } = usePromiseTracker({
    area: AREA.NEW_PLAYER,
  });

  useEffect(() => {
    const abort_controller = new AbortController();

    const fetch_new_player = async () => {
      try {
        const queries = [];
        queries.push(`start_date=${start_date.toFormat('yyyy-MM-dd')}`);
        queries.push(`end_date=${end_date.toFormat('yyyy-MM-dd')}`);
        queries.push(`selectedWebsite=${selectedWebsite || ''}`);
        queries.push(`referral=${selectedReferral}`);

        const response = await fetch(
          `/api/player/get-all?${queries.join('&')}`,

          {
            headers: { 'Content-Type': 'application/json' },
            signal: abort_controller.signal,
          }
        );

        const res = await response.json();
        if (!response.ok) throw new Error(res.message);
        const new_players: UserModel[] = res.data.player;
        setTableData(new_players);
        setTotalData(res.data.total.data);
        setTotalPage(res.data.total.page);
      } catch (error: any) {
        if (error.name === 'AbortError') return;
        alert(error.message);
      }
    };

    trackPromise(fetch_new_player(), AREA.NEW_PLAYER);

    return () => {
      abort_controller.abort();
    };
  }, [selectedWebsite, start_date, end_date, selectedReferral]);

  const chart_data = useMemo(() => {
    const groupped_by_date = tableData.reduce(
      (acc, val) => {
        const date = DateTime.fromISO(val.joinDate).toFormat('yyyy-MM-dd');
        if (!acc[date]) acc[date] = {};
        if (!acc[date][val.website]) acc[date][val.website] = 0;
        acc[date][val.website]++;
        return acc;
      },
      {} as { [k: string]: { [l: string]: number } }
    );

    return groupped_by_date;
  }, [tableData]);

  useEffect(() => {
    getLastFetch();
  }, [getLastFetch]);

  const date_range_picker_ref = useRef<HTMLDivElement>(null);

  const toggleDateRangePicker = () => {
    if (!display_date_range_picker) {
      set_display_date_range_picker(true);
    } else {
      date_range_picker_ref.current?.classList.replace(
        'fade-in-top',
        'fade-out-top'
      );
      if (selectionRange.startDate)
        set_start_date(DateTime.fromJSDate(selectionRange.startDate));
      if (selectionRange.endDate)
        set_end_date(DateTime.fromJSDate(selectionRange.endDate));

      setTimeout(() => {
        set_display_date_range_picker(false);
      }, 300);
    }
  };

  const selectedTags = (tags: any) => {
    setCurrentPage(1);
    const joined_array_in_string = tags.join(',');

    setSelectedReferral(joined_array_in_string);
  };

  return (
    <>
      <Helmet>
        <title>Player - Growth Tracker</title>
      </Helmet>

      {display_date_range_picker && (
        <div
          className='fixed left-0 top-0 right-0 bottom-0 z-10'
          onClick={toggleDateRangePicker}
        />
      )}

      <div className='p-4 overflow-y-scroll h-screen'>
        <div className='flex flex-auto justify-between items-center w-full'>
          <div>
            <h1 className='text-3xl font-bold mb-0'>
              Data Player Baru{' '}
              <span className='text-base ml-2 font-light text-gray-500'>
                Total: {thousandSeparator({ value: totalData })}
              </span>
            </h1>
          </div>

          <div className='flex space-x-2'>
            {/* <DebounceInput
              type='text'
              className='shadow-sm focus:ring-emerald-500 focus:border-emerald-500 block sm:text-sm border-gray-300 rounded-md ml-2'
              value={selectedReferral}
              placeholder='By referral'
              onChange={e => {
                setSelectedReferral(e.target.value);
                setCurrentPage(1);
              }}
              debounceTimeout={500}
            /> */}

            <TagsInput selectedTags={selectedTags} />

            <Select
              displayValue={selectedWebsite || 'ALL'}
              nullOptions={true}
              nullMessage={'ALL'}
              onChange={e => {
                setSelectedWebsite(e);
                setCurrentPage(1);
              }}
              options={Object.values(WebsiteName)}
              value={selectedWebsite}
            />

            <DateRangePickerPlayer
              toggleDateRangePicker={toggleDateRangePicker}
              selectionRange={selectionRange}
              setSelectionRange={setSelectionRange}
              displayDateRangePicker={display_date_range_picker}
              dateRangePickerRef={date_range_picker_ref}
            />

            <button
              className={classNames(
                'flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer active:opacity-90',

                fetchAllowed
                  ? 'bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 text-white'
                  : 'bg-gray-300 text-gray-100'
              )}
              onClick={fetchUser}
              disabled={!fetchAllowed}
            >
              {fetching ? (
                <LoadingIndicator colorScheme={'light'} />
              ) : (
                <>
                  <CloudArrowDownIcon className='-ml-1 mr-2 h-5 w-5' /> Fetch
                </>
              )}
            </button>
          </div>
        </div>

        <div className='py-5 px-6 my-4 bg-white rounded-md shadow-lg'>
          <LineChartPlayer
            chartData={chart_data}
            showingChart={selectedWebsite || ''}
          />
        </div>

        <div className='mt-4 rounded-md overflow-auto text-sm shadow'>
          <table className='font-sans w-full h-full rounded-t-md '>
            <thead className='text-white whitespace-nowrap'>
              <tr>
                <th className='py-3 bg-teal-600'>ID Player</th>
                <th className='py-3 bg-teal-600'>Referral</th>
                <th className='py-3 bg-teal-600'>Tanggal Daftar</th>
                <th className='py-3 bg-teal-600'>Jam Daftar</th>
                <th className='py-3 bg-teal-600'>No.Telepon</th>
                <th className='py-3 bg-teal-600'>Nama Bank</th>
                <th className='py-3 bg-teal-600'>Nama Rekening</th>
                <th className='py-3 bg-teal-600'>Nomor Rekening</th>
                <th className='py-3 bg-teal-600'>Web</th>
              </tr>
            </thead>

            <tbody className='text-gray-500 text-center h-full whitespace-nowrap divide-y divide-gray-200'>
              {tableData.length ? (
                tableData
                  .slice(
                    (+currentPage - 1) * page_size,
                    +currentPage * page_size
                  )
                  .map((data: UserModel, i) => (
                    <tr key={data.playerId + data.website}>
                      <td className='py-2 px-3'>{data.playerId}</td>
                      <td className='py-2 px-3'>{data.referral}</td>

                      <td className='py-2 px-3'>
                        {DateTime.fromISO(data.joinDate)
                          .setZone('UTC+7')
                          .toFormat('d MMMM yyyy')}
                      </td>

                      <td className='py-2 px-3'>
                        {DateTime.fromISO(data.joinDate)
                          .setZone('UTC+7')
                          .toFormat('HH:mm')}
                      </td>

                      <td className='py-2 px-3'>{data.phone}</td>
                      <td className='py-2 px-3'>{data.namaBank}</td>
                      <td className='py-2 px-3'>{data.namaRekening}</td>
                      <td className='py-2 px-3'>{data.nomorRekening}</td>
                      <td className='py-2 px-3'>{data.website}</td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={11}>
                    <NoDataPlayer />
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className='bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6'>
            <div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
              <div>
                <p className='text-sm text-gray-700 mr-10'>
                  Menampilkan{' '}
                  <span className='font-medium'>
                    {totalData > 0 ? currentPage * 100 - 99 : 0}
                  </span>{' '}
                  sampai{' '}
                  <span className='font-medium'>
                    {currentPage * 100 > totalData
                      ? totalData
                      : currentPage * 100}
                  </span>{' '}
                  dari{' '}
                  <span className='font-medium'>
                    {thousandSeparator({ value: totalData })}
                  </span>{' '}
                  hasil
                </p>
              </div>

              <div>
                <nav className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'>
                  <button
                    className='relative inline-flex justify-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                    onClick={() => setCurrentPage(1)}
                  >
                    <ChevronDoubleLeftIcon className='h-5 w-5' />
                  </button>

                  <button
                    className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                    onClick={() => {
                      if (currentPage === 1) return;
                      setCurrentPage(currentPage => currentPage - 1);
                    }}
                  >
                    <ChevronLeftIcon className='h-5 w-5' />
                  </button>

                  {pageArray(currentPage, totalPage).map(el => (
                    <button
                      className={classNames(
                        currentPage === el
                          ? 'z-10 bg-teal-50 border-teal-500 text-teal-600'
                          : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',

                        'hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium justify-center w-12'
                      )}
                      onClick={() => setCurrentPage(el)}
                      key={`Page Button #${el}`}
                    >
                      {el}
                    </button>
                  ))}

                  <button
                    className='relative inline-flex justify-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                    onClick={() => {
                      if (currentPage === totalPage) return;
                      setCurrentPage(currentPage => currentPage + 1);
                    }}
                  >
                    <ChevronRightIcon className='h-5 w-5' />
                  </button>

                  <button
                    className='relative inline-flex justify-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 w-12'
                    onClick={() => setCurrentPage(totalPage)}
                  >
                    <ChevronDoubleRightIcon className='h-5 w-5' />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      {getting_new_players && (
        <div className='bg-teal-900 bg-opacity-75 fixed top-0 left-0 bottom-0 right-0 flex items-center justify-center z-30'>
          <div className='text-white'>
            <div className='text-center'>
              <LoadingIndicator colorScheme='light' />
            </div>
            Sabar ya. Datanya banyak...
          </div>
        </div>
      )}
    </>
  );
};

export default Pemain;
